<template>
  <div
    v-loading="loading"
    element-loading-text="Fetching Address fields.... Please wait...."
    element-loading-spinner="el-icon-loading"
  >
    <el-row>
      <el-col :span="1">
        <el-button @click="goBackToCustomization" class="ml-10" size="mini">
          <i class="el-icon-arrow-left"></i>
        </el-button>
      </el-col>
      <el-col :span="10">
        <span class="config-style"> Address Configuration </span>
      </el-col>
      <el-col :span="13">
        <el-button
          type="primary"
          style="float: right"
          @click="saveCustomization"
          >{{
            currentEntity?.paymentConfiguration ? "Update" : "Save"
          }}</el-button
        >
      </el-col>
    </el-row>
    <div>
      <el-card>
        <el-table
          :data="paymentFields"
          border
          v-loading="tableLoading"
          v-if="!refresh"
        >
          <el-table-column label="Field Name">
            <template slot-scope="scope">
              <b>{{ scope.row.label }} </b>
              <span v-if="scope.row.dataKey == 'state'" class="note">
                <br />
                Note: Please use the region code for the state value.<br />
                For example, if your state is New York, the region code should
                be NY.
              </span>
              <span v-if="scope.row.dataKey == 'country'" class="note">
                <br />Note: Please use the country code for the country
                value.<br />
                For example, if your country is United States / USA, the country
                code should be US.
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Mapped Field">
            <template slot-scope="scope">
              <el-select
                v-if="
                  scope.row.dataKey &&
                  scope.row.dataKey != 'notification_template' &&
                  scope.row.dataKey != 'receiver_email' &&
                  scope.row.dataKey != 'owner_notification_template'
                "
                v-model="form[scope.row.dataKey]"
                placeholder="Select"
              >
                <el-option
                  v-for="item in entityFields"
                  :key="item.template_key"
                  :label="item.label"
                  :value="item.template_key"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.template_name
                  }}</span>
                </el-option>
              </el-select>
              <el-select
                v-else-if="scope.row.dataKey == 'receiver_email'"
                v-model="form[scope.row.dataKey]"
                placeholder="Select"
              >
                <el-option
                  v-for="item in emailFields"
                  :key="item.template_key"
                  :label="item.label"
                  :value="item.template_key"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.template_name
                  }}</span>
                </el-option>
              </el-select>
              <el-select
                v-else
                v-model="form[scope.row.dataKey]"
                placeholder="Select"
              >
                <el-option
                  v-for="item in notificationTemplates"
                  :key="item._id"
                  :label="item.name"
                  :value="item._id"
                >
                  <!-- <span style="float: left">{{ item.name }}</span> -->
                  <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.template_name
                  }}</span> -->
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EntitiesHelper from "../../mixins/EntitiesHelper";
import { fetchEntityNotificationTemplates } from "../../repo/notificationTemplatesRepo";
import { updateEntityData } from "@/repo/entityRepo";
// import { postAPICall } from "../../helpers/httpHelper";

// const fieldsList = require("../../components/templates/fields.json");

const formDefaults = {
  name: "",
  email: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  notification_template: "",
  receiver_email: "",
  owner_notification_template: "",
};
const defaultTranslations = [
  {
    label: "Name",
    placeholder: "Full Name",
    dataKey: "name",
  },
  {
    label: "Email",
    placeholder: "Email",
    dataKey: "email",
  },
  {
    label: "Address",
    placeholder: "Address",
    dataKey: "address",
  },
  {
    label: "City",
    placeholder: "City",
    dataKey: "city",
  },
  {
    label: "State",
    placeholder: "State",
    dataKey: "state",
  },
  {
    label: "Country",
    placeholder: "Country",
    dataKey: "country",
  },
  {
    label: "Zip Code",
    placeholder: "Zip Code",
    dataKey: "zipCode",
  },
  {
    label: "Receiver Notification Template",
    placeholder: "Notification Template",
    dataKey: "notification_template",
  },
  {
    label: "Receiver Email",
    placeholder: "Receiver Email",
    dataKey: "receiver_email",
  },
  {
    label: "Owner Notification Template",
    placeholder: "Notification Template",
    dataKey: "owner_notification_template",
  },
];

export default {
  name: "EntityPaymentConfiguration",
  mixins: [EntitiesHelper],
  props: ["currentEntity"],
  async mounted() {
    this.loading = true;
    console.log("Current entity ", this.currentEntity);
    this.notificationTemplates = await fetchEntityNotificationTemplates({
      entity_id: this.currentEntity._id,
    });
    await this.getFieldsByEntity(this.currentEntity._id);
    if (this.currentEntity?.paymentConfiguration) {
      this.form = this.currentEntity.paymentConfiguration;
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("entities", [
      "getEntityCreatedStatus",
      "getEntityCreatedData",
      "getEntityDataById",
      "getEntityDataUpdateStatus",
      "getEntityErrors",
      // "getAllEntities",
      "getEntityRecordsForTable",
    ]),
  },
  data() {
    return {
      tableLoading: false,
      form: formDefaults,
      refresh: false,
      loading: false,
      paymentFields: defaultTranslations,
      entityFields: [],
      emailFields: [],
      notificationTemplates: [],
    };
  },
  methods: {
    // async fields()
    // {
    //     let fields=[...fieldsList.allFormFields,...fieldsList.allContentFields,...fieldsList.allAdvancedFields];
    //     let fieldTypes=await fields.map(field=>{
    //         return field.inputType;
    //     })
    //     console.log(fieldTypes);

    // },
    async getFieldsByEntity(id) {
      this.tableLoading = true;
      let include = [
        "SINGLE_LINE_TEXT",
        "SELECT",
        "NUMBER",
        "LIST",
        "CONCATENATE",
      ];
      let allFields = await this.fetchEntityDetails(
        id,
        false,
        false,
        false,
        true
      );
      this.entityFields = allFields.filter((field) => {
        if (field.input_type == "ENTITY" && !field.allow_multiple) {
          return field;
        }
        if (field.input_type == "ENTITY_VARIABLE") {
          return include.includes(field.inputType);
        }
        return include.includes(field.inputType);
      });
      this.emailFields = allFields.filter((field) => {
        if (
          field.input_type == "EMAIL" ||
          (field.input_type == "SINGLE_LINE_TEXT" && field.label == "Email")
        ) {
          return field;
        }
      });

      console.log("Fields", this.entityFields);
      this.tableLoading = false;
    },

    async updateLocalEntityData(id, params) {
      this.loading = true;
      let currentEntity = JSON.parse(
        JSON.stringify({ ...this.currentEntity, ...params })
      );
      let tempEntity = {
        ...currentEntity,
        ...{
          templates: currentEntity.templates.map((t) => {
            if (t?.templateInfo) {
              delete t.templateInfo;
            }
            return t;
          }),
        },
      };
      updateEntityData(id, tempEntity);
      this.loading = false;
    },

    async saveCustomization() {
      this.loading = true;
      let params = {
        id: this.currentEntity._id,
        ...this.currentEntity,
        paymentConfiguration: this.form,
        ...{
          templates: this.currentEntity.templates.map((t) => {
            if (t?.templateInfo) {
              delete t.templateInfo;
            }
            return t;
          }),
        },
      };

      await this.$store.dispatch("entities/updateEntityById", params);
      if (this.getEntityDataUpdateStatus) {
        this.$notify({
          title: "Success",
          message: "Entity updated successfully",
          type: "success",
        });
        this.updateLocalEntityData(params._id, params);
        this.loading = false;
        this.$emit("goBack", false, true);
      } else {
        if (this.getEntityErrors) {
          console.log(this.getEntityErrors);

          this.$notify({
            title: "Error",
            message: "Entity name already existed.!",
            type: "error",
          });
          this.loading = false;
        } else {
          this.$notify({
            title: "Error",
            message: "Error in updating Entity",
            type: "error",
          });
          this.loading = false;
        }
      }
    },
    goBackToCustomization() {
      this.loading = true;
      this.$emit("goBack");
      this.loading = false;
    },
  },
  beforeDestroy() {
    this.form = formDefaults;
  },
};
</script>
<style scoped>
.note {
  color: #c100007a !important;
}
.title-input {
  background-color: rgba(255, 255, 255, 0.35);
  color: #222222 !important;
  padding: 0 !important;
  border: none !important;
  font-size: 1.5em !important;
  box-shadow: none;
  align-items: stretch;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.centered-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally */
  justify-content: center;
  /* Center vertically */
  height: 85vh;
  text-align: center;
  /* Center the text inside */
}

.centered-div img,
.centered-div h1,
.centered-div span,
.centered-div el-button {
  margin: 5px 0;
  /* Add some vertical spacing between items */
}

.buttons {
  display: flex;
  margin-bottom: 10px;
  margin-left: 20px;
}

.button {
  width: 150px;
}

.center-cell-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.sidemenu {
  height: 420px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
}

.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

.cover {
  margin-top: 90px;
}

.aggregations-table {
  margin-top: 5px;
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
}
.formula-dialog {
  .form-left {
    min-height: 0px !important;
  }
  .variables-fields {
    display: flex !important;
    justify-content: space-between !important;
  }
  .operaters {
    text-align: center;
    margin-top: 1em;
    img {
      max-width: 25px;
    }
    .el-button-group {
      button {
        margin-bottom: 5px;

        @media (max-width: 600px) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}
.artboard {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #d2d2d2;
  border-radius: 0px;
  padding: 0.25em;
  min-height: 60px;
  display: flex;
  align-items: center;
  overflow: scroll;
  width: 100%;
  background: #ffffff;
  .el-tag {
    margin-left: 0.5em;
  }
  .btn-variable {
    background-color: #000577;
    border-radius: 30px;
    color: #ffffff;
    border: #000577;
    padding: 10px 35px 10px 15px;
    position: relative;
    &.active {
      background-color: #dd5050 !important;
      box-shadow: 0 0 0 4px #efefef;
    }
    &:hover {
      box-shadow: 0 0 0 4px #f7f7f7;
    }
    img {
      position: absolute;
      top: 7px;
      right: 7px;
      width: 20px;
      height: 20px;
      opacity: 0.7;
    }
    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  .btn-operation {
    display: flex;
    align-items: center;
    margin: 0 5px;
    padding: 5px 6px;
    img {
      max-width: 15px;
    }
    &.active {
      border: 2px solid #ff3e3e !important;
      background-color: #f34423 !important;
    }
  }
  .text-button {
    font-size: 4em;
    line-height: 15px;
    span {
      position: relative;
      margin-top: -9px;
      display: block;
      color: #c4c4c4;
    }
  }
  .el-button + .el-button {
    margin: 0 5px;
  }
}
.formula-dialog .form-right .artboard .btn-variable {
  margin-top: 5px !important;
}
</style>
<style lang="scss">
.user-menu-container {
  background: #f5f7ff;
  border: 1px solid rgba(168, 171, 255, 0.3);
  border-radius: 20px;
  padding: 2em;
  position: relative;

  .menuPreviewButton {
    position: absolute;
    top: -1.5em;
    right: 15em;
    border-radius: 5px;
  }

  .menuSubmitButton {
    position: absolute;
    top: -1.5em;
    right: 2em;
    right: 6.5em;
    border-radius: 5px;
  }

  .menuDeleteButton {
    position: absolute;
    top: -1.5em;
    right: 1em;
    border-radius: 5px;
  }

  .el-tree {
    background-color: transparent;

    .el-tree-node__content {
      height: 6em;

      &:hover {
        background-color: #f7f9fd;
      }
    }
  }

  .each-item {
    width: 25em;
    overflow: visible;
    margin-bottom: 1em;
    min-height: 5em;
    position: relative;
    z-index: 20;

    .el-card__body {
      padding-top: 0.75em;
    }

    &:last-child {
      margin-bottom: 0em;
    }

    & + .sub-item-1 {
      position: relative;
      margin-left: 3.5em;
      z-index: 15;

      &:after {
        content: "";
        position: absolute;
        top: 2em;
        left: -2em;
        width: 2em;
        border-top: 2px dashed #285fd3;
        height: 1px;
      }

      &:before {
        content: "";
        position: absolute;
        top: -3.75em;
        left: -2em;
        width: 2px;
        border-left: 2px dashed #285fd3;
        height: calc(100% + 1em);
      }
    }

    & + .sub-item-2 {
      position: relative;
      margin-left: 5.5em;
      z-index: 15;

      &:after {
        content: "";
        position: absolute;
        top: 2em;
        left: -4em;
        width: 4em;
        border-top: 2px dashed #285fd3;
        height: 1px;
      }

      &:before {
        content: "";
        position: absolute;
        top: -3.75em;
        left: -4em;
        width: 2px;
        border-left: 2px dashed #285fd3;
        height: calc(100% + 1em);
      }
    }
  }
}
</style>
<style scoped>
.config-style {
  margin-left: 10px;
  font-weight: bold;
}
</style>
